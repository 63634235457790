import Vue from 'vue'
import VueRouter from 'vue-router'
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function(location) {
  return originalPush.call(this, location).catch(() => {})
}
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home/Home.vue'),
    meta: {title: '三三科技-首页介绍'}
  },
  {
    path: '/product',
    name: 'Product',
    component: () => import('@/views/Product/Index.vue'),
    meta: {title: '三三科技-产品中心'}
  },
  {
    path: '/product/details',
    name: 'Product Details',
    component: () => import('@/views/Product/Details.vue'),
    meta: {title: '三三科技-产品详情'}
  },
  {
    path: '/application',
    name: 'Application',
    component: () => import('@/views/Application/Index.vue'),
    meta: {title: '三三科技-应用领域'}
  },
  {
    path: '/solution',
    name: 'Solution',
    component: () => import('@/views/Solution/Index.vue'),
    meta: {title: '三三科技-解决方案'}
  },
  {
    path: '/cooperation',
    name: 'Cooperation',
    component: () => import('@/views/Cooperation/Index.vue'),
    meta: {title: '三三科技-商务合作'}
  },
  {
    path: '/news',
    name: 'News',
    component: () => import('@/views/News/Index.vue'),
    meta: {title: '三三科技-新闻资讯'}
  },
  {
    path: '/news/details',
    name: 'News Details',
    component: () => import('@/views/News/Details.vue'),
    meta: {title: '三三科技-新闻详情'}
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('@/views/Contact/Index.vue'),
    meta: {title: '三三科技-联系方式'}
  },
  {
    path: '/recruitment',
    name: 'Recruitment',
    component: () => import('@/views/Recruitment/Index.vue'),
    meta: {title: '三三科技-人才招聘'}
  },
  {
    path: '/aboutus',
    name: 'Aboutus',
    component: () => import('@/views/Aboutus/Index.vue'),
    meta: {title: '三三科技-关于我们'}
  },
]

const router = new VueRouter({
  mode: "history",
  routes
})
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = "三三科技"
  }
  next()
})
router.afterEach(() => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
})
export default router