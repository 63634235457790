<template>
  <div class="footer-content">
    <div class="footer-navigation">
      <div class="navigation-item">
        <div class="navigation-content" v-for="item in navigationList" :key="item.id">
          <div class="navigation-title">{{ $t(item.title) }}</div>
          <div class="navigation-division"></div>
          <div class="navigation-list">
            <div class="navigation-li" v-for="val in item.list" :key="val.id" @click="toNavigation(val)">{{ $t(val.title) }}</div>
          </div>
        </div>
        <div class="navigation-content contact">
          <div class="navigation-title">{{ $t('footer.contact.title') }}</div>
          <div class="navigation-division"></div>
          <div class="navigation-list">
            <div class="navigation-li">{{ $t('footer.contact.phone') }}</div>
            <div class="navigation-li">{{ $t('footer.contact.name') }}</div>
            <div class="navigation-li">{{ $t('footer.contact.mail') }}</div>
            <div class="navigation-li">{{ $t('footer.contact.address') }}</div>
          </div>
        </div>
      </div>
      <div class="wechat-csd">
        <img class="wechat-info" src="https://img.sansanchina.com/pc/WeChat-logo.png" />
        <span>{{ $t('footer.contact.wechat') }}</span>
      </div>
    </div>
    <div class="footer-copyright">
      <div class="copyright-content">
        <div class="left-copyright">Copyright 2018 - {{ year }} {{ $t('footer.contact.company') }}：<a
            href="https://beian.miit.gov.cn/" target="_blank" rel="nofollow">{{ $t('footer.contact.ipc') }}</a></div>
        <div class="right-disclaimer" @click="disclaimer">{{ $t('footer.contact.disclaimers') }}</div>
      </div>
    </div>
    <!-- 免责声明 -->
    <el-dialog :visible.sync="dialogVisible" :title="$t('footer.contact.disclaimers')" width="50%" center>
      <div class="disclaimer-style">
        <p>{{ $t('footer.disclaimerA') }}</p>
        <p>{{ $t('footer.disclaimerB') }}</p>
        <p>{{ $t('footer.disclaimerC') }}</p>
        <p>{{ $t('footer.disclaimerD') }}</p>
        <p>{{ $t('footer.disclaimerE') }}</p>
        <p>{{ $t('footer.disclaimerF') }}</p>
        <p>{{ $t('footer.disclaimerG') }}</p>
        <p>{{ $t('footer.disclaimerH') }}</p>
        <p>{{ $t('footer.disclaimerI') }}</p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Footer',
  data() {
    return {
      year: '', // 当前年份
      dialogVisible: false, // 免责声明弹窗
      navigationList: [
        {
          id: 1,
          title: 'footer.navigationA.title',
          list: [
            { id: 1, title: 'footer.navigationA.listA', path: '/aboutus' },
            { id: 2, title: 'footer.navigationA.listB', path: '/aboutus' },
            { id: 3, title: 'footer.navigationA.listC', path: '/aboutus' },
            { id: 4, title: 'footer.navigationA.listD', path: '/aboutus' },
          ]
        },
        {
          id: 2,
          title: 'footer.navigationB.title',
          list: [
            { id: 1, title: 'footer.navigationB.listA', path: '/product?type=1' },
            { id: 2, title: 'footer.navigationB.listB', path: '/product?type=2' },
            { id: 3, title: 'footer.navigationB.listC', path: '/product?type=3' },
            { id: 4, title: 'footer.navigationB.listD', path: '/product?type=4' },
          ]
        },
        {
          id: 3,
          title: 'footer.navigationC.title',
          list: [
            { id: 1, title: 'footer.navigationC.listA', path: '/solution' },
            { id: 2, title: 'footer.navigationC.listB', path: '/solution' },
            { id: 3, title: 'footer.navigationC.listC', path: '/solution' },
            { id: 4, title: 'footer.navigationC.listD', path: '/solution' },
            { id: 5, title: 'footer.navigationC.listE', path: '/solution' },
          ]
        },
        {
          id: 4,
          title: 'footer.navigationD.title',
          list: [
            { id: 1, title: 'footer.navigationD.listA', path: '/contact' },
            { id: 2, title: 'footer.navigationD.listB', path: '/contact' },
            { id: 3, title: 'footer.navigationD.listC', path: '/contact' },
          ]
        },
      ]
    }
  },
  created() {
    this.year = new Date().getFullYear()
  },
  methods: {
    // 跳转
    toNavigation(e) {
      this.$router.push(e.path)
    },
    // 免责声明
    disclaimer() {
      this.dialogVisible = true
    },
  }
}

</script>
<style lang="scss" scoped>
.footer-content {
  width: 100%;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  background-color: #141621;
  // background: url(https://img.xd-qt.com/pc/bg-foot.jpg) no-repeat center;
  // background-size: cover;

  .footer-navigation {
    width: 73.5688%;
    margin: 0 auto 60px;
    display: flex;
    justify-content: space-between;

    .navigation-item {
      width: 1120px;
      display: flex;
      justify-content: space-between;

      .navigation-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .navigation-title {
          font-size: 18px;
          color: #cfd4dc;
        }

        .navigation-division {
          margin-top: 25px;
          margin-bottom: 15px;
          width: 20px;
          height: 3px;
          background-color: #19288A;
        }

        .navigation-list {
          display: flex;
          flex-direction: column;
          .navigation-li {
            height: 36px;
            font-size: 14px;
            line-height: 16px;
            display: flex;
            align-items: center;
            color: #9ea4ae;
            cursor: pointer;
            text-align: justify;
            &:hover {
              color: #cfd4dc;
            }
          }
        }
      }

      .contact {
        width: 270px;

        .navigation-list {
          .navigation-li {
            height: auto;
            padding: 5px 0;

            &:hover {
              cursor: auto;
              color: #9ea4ae;
            }

            &:nth-child(1) {
              height: 45px;
              font-size: 36px;
              color: #FFF;
            }
          }
        }
      }
    }

    .wechat-csd {
      display: flex;
      flex-direction: column;
      align-items: center;

      .wechat-info {
        width: 130px;
        height: 130px;
      }

      span {
        height: 40px;
        font-size: 15px;
        display: flex;
        align-items: center;
        color: #9ea4ae;
      }
    }
  }

  .footer-copyright {
    padding: 15px 0;
    border-top: 1px solid #39434e;
    font-size: 14px;
    color: #81868e;

    a {
      text-decoration: none;
      color: #81868e;
    }

    a:link {
      text-decoration: none;
    }

    a:visited {
      text-decoration: none;
    }

    a:hover {
      text-decoration: none;
      color: #cfd4dc;
    }

    a:active {
      text-decoration: none;
    }

    .copyright-content {
      width: 73.5688%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .right-disclaimer {
        &:hover {
          cursor: pointer;
          color: #cfd4dc;
        }
      }
    }
  }

  .disclaimer-style {
    font-size: 15px;

    p {
      margin: 10px 0;
      line-height: 1.6;
    }
  }
}</style>