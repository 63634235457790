<template>
  <div class="menu-content" v-show="isVisable">
    <div class="menu-item" @click="customerService">
      <img src="https://img.sansanchina.com/pc/customer-service.png" />
      <span>{{ $t('frame.item1') }}</span>
    </div>
    <el-popover
      placement="right"
      width="260"
      trigger="hover">
      <div class="hover-content">
        <span class="unit">{{ $t('frame.title1') }}</span>
        <span class="value">{{ $t('frame.title2') }}</span>
      </div>
      <div slot="reference" class="menu-item">
        <img src="https://img.sansanchina.com/pc/phone-number.png" />
        <span>{{ $t('frame.item2') }}</span>
      </div>
    </el-popover>
    <el-popover
      placement="right"
      width="200"
      trigger="hover">
      <div class="hover-content">
        <img class="image" src="https://img.sansanchina.com/pc/wechat-qrcode.jpg" />
        <span class="unit">{{ $t('frame.title3') }}</span>
      </div>
      <div slot="reference" class="menu-item">
        <img src="https://img.sansanchina.com/pc/wechat-info.png" />
      <span>{{ $t('frame.item3') }}</span>
      </div>
    </el-popover>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Menu',
  data() {
    return {
      year: '', // 当前年份
      isVisable: true, // 是否显示
    }
  },
  created() {
    this.year = new Date().getFullYear()
    window.addEventListener('scroll', this.windowScrollListener);
    
  },
  watch: {
    
  },
  methods: {
    customerService() {
      window.open('https://affim.baidu.com/unique_31449627/chat?siteId=16103646&userId=31449627&siteToken=28c99a6d0ead8a686ba082fbe9cd5aa1')
    },
    windowScrollListener() {
      //获取操作元素最顶端到页面顶端的垂直距离
      var scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
      if (scrollTop <= 200) {
        if (this.$route.path == '/') {
          this.isVisable = false;
        }
      } else {
        this.isVisable = true;
      }
    }
  }
}

</script>
<style lang="scss" scoped>
.menu-content {
  width: 80px;
  height: 240px;
  position: fixed;
  right: 10px;
  border-radius: 4px;
  top: calc(100vh - (50vh + 150px));
  background-color: #FFF;
  box-shadow: 0px 3px 8px 0px rgba(25, 40, 138, 0.5), 0px 3px 8px 0px rgba(46, 65, 185, 0.2);
  
  .menu-item {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-bottom: 1px solid #F2F6FC;
    box-sizing: border-box;
    cursor: pointer;
    transition: all 100ms cubic-bezier(.36,.66,.04,1);
    border-radius: 4px;
    &:active {
      background-color: #9ba9bd;
    }
    &:hover {
      background-color: #F2F6FC;
    }
    img {
      width: 35px;
      height: 35px;
      user-select: none;
    }
    span {
      font-size: 12px;
      color: #19288A;
      margin-top: 5px;
      user-select: none;
    }
  }
}
</style>
<style>
.hover-content {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.hover-content .image {
  width: 90px;
  height: 90px;
  margin-bottom: 3px;
}
.hover-content .unit {
  font-size: 14px;
  color: #606266;
}
.hover-content .value {
  color: #19288A;
  font-size: 24px;
  font-weight: 800;
  margin-top: 10px;
}
</style>