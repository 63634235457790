<template>
  <div id="app">
    <Header />
    <router-view class="router-style" />
    <transition name="el-fade-in-linear">
      <Menu />
    </transition>
    <!-- 返回顶部 -->
    <el-backtop :bottom="100" :right="40" :visibility-height="400" />
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Menu from '@/components/Menu'

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    Menu
  },
  data: () => ({
    
  }),
  mounted() {}
};
</script>
<style lang="scss" scoped>
#app {
  font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#particles-js {
  width: 100%;
  height: calc(100%);
  position: fixed;
  z-index: -100;
}
// /*滚动条的宽度*/
// ::-webkit-scrollbar {
//     width:10px;
//     height: 10px;
// }
// /*外层轨道。*/
// ::-webkit-scrollbar-track {
//     width: 4px;
//     background-color:#F1F1F1;
//     -webkit-border-radius: 2em;
//     -moz-border-radius: 2em;
//     border-radius: 2em;
// }
// /*滚动条的设置*/
// ::-webkit-scrollbar-thumb {
//     background-color:#C1C1C1;
//     background-clip: padding-box;
//     min-height: 28px;
//     -webkit-border-radius: 2em;
//     -moz-border-radius: 2em;
//     border-radius:2em;
// }
// /*滚动条移上去的背景*/
// ::-webkit-scrollbar-thumb:hover {
//     background-color: #2db0c2;
// }
// @font-face {
//   font-family: "思源宋体 SemiBold";
//   src: url("//img.sansanchina.com/font/X0b1tURk9RFv.woff2") format("woff2"),
//   url("//img.sansanchina.com/font/X0b1tURk9RFv.woff") format("woff");
//   font-display: swap;
// }

// @font-face {
//   font-family: "思源黑体 Regular";
//   font-weight: 400;
//   src: url("//img.sansanchina.com/font/sQm3WRrXQQyS.woff2") format("woff2"),
//   url("//img.sansanchina.com/font/sQm3WRrXQQyS.woff") format("woff");
//   font-display: swap;
// }
.router-style {
  margin-top: 90px !important;
}

</style>
