<template>
  <div class="header-content">
    <div class="header-center">
      <div class="left-content">
        <img class="logo-icon animate__animated animate__bounceIn" @click="onTabs(0)" :src="$t('header.logo')" :alt="$t('header.logoTitle')" :title="$t('header.logoTitle')" />
      </div>
      <div class="tabs-content animate__animated animate__fadeInDown">
        <span class="hvr-underline-from-center" :class="{'selected': index == tabIndex}" v-for="(item, index) in tabList" :key="index" @click="onTabs(index)">{{ $t(item) }}</span>
      </div>
      <div class="right-content animate__animated animate__flip">
        <el-dropdown :show-timeout="0" placement="bottom" @command="handleCommand">
          <img class="chinese-english" src="@/assets/images/chinese-english.png" />
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="zh">简体中文</el-dropdown-item>
            <el-dropdown-item command="en">English</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Header",
  data() {
    return {
      tabList: [
        'header.tabsA',
        'header.tabsB',
        'header.tabsC',
        'header.tabsD',
        // 'header.tabsE',
        'header.tabsF',
        'header.tabsG',
        'header.tabsH',
        // 'header.tabsI',
        'header.tabsZ',
      ],
      tabIndex: 0
    }
  },
  watch: {
    $route:{
      handler(val){
        // console.log(val.name)
        switch (val.name) {
        case 'Home':
          this.tabIndex = 0
          break;
        case 'Product':
          this.tabIndex = 1
          break;
        case 'Product Details':
          this.tabIndex = 1
          break;
        case 'Application':
          this.tabIndex = 2
          break;
        case 'Solution':
          this.tabIndex = 3
          break;
        // case 'Cooperation':
        //   this.tabIndex = 4
        //   break;
        case 'News':
          this.tabIndex = 4
          break;
        case 'News Details':
          this.tabIndex = 4
          break;
        case 'Contact':
          this.tabIndex = 5
          break;
        case 'Recruitment':
          this.tabIndex = 6
          break;
        case 'Aboutus':
          this.tabIndex = 7
          break;
      }
      },
      deep: true
    }
  },
  created() {
    
  },
  mounted() {
    
  },
  methods: {
    // 导航栏切换
    onTabs(e) {
      this.tabIndex = e
      switch (e) {
        case 0:
          this.$router.push({path: '/'})
          break;
        case 1:
          this.$router.push({path: '/product'})
          break;
        case 2:
          this.$router.push({path: '/application'})
          break;
        case 3:
          this.$router.push({path: '/solution'})
          break;
        // case 4:
        //   this.$router.push({path: '/cooperation'})
        //   break;
        case 4:
          this.$router.push({path: '/news'})
          break;
        case 5:
          this.$router.push({path: '/contact'})
          break;
        case 6:
          this.$router.push({path: '/recruitment'})
          break;
        case 7:
          this.$router.push({path: '/aboutus'})
          break;
      }
    },
    // 语言切换
    handleCommand(command) {
      this.$i18n.locale = command
      localStorage.setItem("lang", command)
    }
  }
}

</script>
<style lang="scss" scoped>
.header-content {
  width: 100%;
  min-height: 90px;
  position: fixed;
  // background-color: #FFF;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 100;
  backdrop-filter: saturate(180%) blur(20px);
  background-color: rgba(255,255,255,0.72);
  box-shadow: 0px 1px 2px #e1e5e9;
  .header-center {
    max-width: 73.5688%;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
  }
  .left-content {
    width: 125px;
    .logo-icon {
      width: 54px;
      height: 67px;
      cursor: pointer;
    }
  }
  .tabs-content {
    width: 71.111%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    span {
      padding: 1.5% 0.6%;
      font-size: 18px;
      font-weight: 500;
      color: #222;
      cursor: pointer;
      font-family: "思源黑体 Regular";
      user-select: none;
      -webkit-transition: all .3s cubic-bezier(.36, .66, .04, 1);
      transition: all .3s cubic-bezier(.36, .66, .04, 1);
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
      box-sizing: border-box;
      &:hover {
        color: #19288A;
      }
    }
    
    /* Underline From Center */
    .hvr-underline-from-center {
      display: inline-block;
      vertical-align: middle;
      -webkit-transform: perspective(1px) translateZ(0);
      transform: perspective(1px) translateZ(0);
      box-shadow: 0 0 1px rgba(0, 0, 0, 0);
      position: relative;
      // overflow: hidden;
      box-sizing: border-box;
    }
    .selected {
      color: #19288A;
      border-bottom: 4px solid #19288A;
    }
   
    .hvr-underline-from-center:before {
      content: "";
      position: absolute;
      z-index: -1;
      left: 51%;
      right: 51%;
      bottom: -4px;
      background-color: #19288A;
      height: 4px;
      -webkit-transition-property: left, right;
      transition-property: left, right;
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s;
      -webkit-transition-timing-function: ease-out;
      transition-timing-function: ease-out;
    }
    .hvr-underline-from-center:hover:before, .hvr-underline-from-center:focus:before, .hvr-underline-from-center:active:before {
      left: 0;
      right: 0;
    }
  }
  .right-content {
    width: 125px;
    display: flex;
    align-items: center;
    justify-content: center;
    .chinese-english {
      width: 26px;
      height: 26px;
      cursor: pointer;
    }
  }
}
</style>
