import Vue from 'vue'
import ElementUI from 'element-ui';
// eslint-disable-next-line no-unused-vars
import animated from 'animate.css'
import 'wowjs/css/libs/animate.css'
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/base.css';
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
import './assets/css/element-variables.scss'
import './assets/css/normalize.css'
import './assets/css/hover-min.css'
import './assets/css/csshake.min.css'
import App from './App.vue'
import router from './router'
import VueParticles from 'vue-particles'  
import VueI18n from 'vue-i18n'
import locale from 'element-ui/lib/locale'
import elementEN from 'element-ui/lib/locale/lang/en' // element英文包
import elementZH from 'element-ui/lib/locale/lang/zh-CN' // element中文包
import selfEN from './assets/language/en'
import selfZH from './assets/language/zh'
Vue.config.productionTip = false

Vue.use(ElementUI)
Vue.use(VueI18n)
Vue.use(VueParticles)
// Vue.use(animated)
let language = null
let lang = localStorage.getItem("lang")
if (lang == null) {
  language = navigator.language
  if (language == 'zh-CN') language = 'zh'
  else language = 'en'
} else {
  language = lang
}
const i18n = new VueI18n({
  locale: language,
  // silentTranslationWarn: true,
  messages: {
    en: {
      ...elementEN,
      ...selfEN
    },
    zh: {
      ...elementZH,
      ...selfZH
    }
  }
})

locale.i18n((key, value) => i18n.t(key, value))

Vue.component(CollapseTransition.name, CollapseTransition)

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
